<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3>
          Чат
        </h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-col
          class="white"
          cols="12"
        >
          <div
            ref="messages"
            class="messages grey lighten-4 pa-3"
          >
            <div
              v-for="(msg, idx) in messages"
              :key="idx"
              class="d-flex"
              :class="{
                'justify-end':!msg.user_id
              }"
            >
              <div
                class="message rounded pa-2 mb-1"
                :class="{
                  'grey lighten-2':msg.user_id,
                  'teal lighten-1':!msg.user_id
                }"
              >
                {{ msg.message }}
              </div>
            </div>
          </div>
          <div class="predefined my-2">
            <v-btn
              v-for="(action, idx) in actions"
              :key="`action-${idx}`"
              class="elevation-0 mr-1"
              :class="{
                'teal lighten-2': predefined === action.text
              }"
              @click="predefined = action.text"
            >
              {{ action.text }}
            </v-btn>
          </div>
          <v-textarea
            v-model="message"
            rows="2"
            placeholder="Введите ваше сообщение"
          />
        </v-col>
        <div class="d-flex justify-end">
          <v-btn
            dark
            color="#5CB7B1"
            class="mx-5 mt-5"
            @click="submit"
          >
            Отправить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from 'vuex';
import axios from "axios";
import {MSG} from "@/http-routes";

export default {
  mixins: [modalWindowMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    rootUserId: {
      type: Number,
      required: true,
    },
    module: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    message: '',
    document: null,
    messages: [],
    actions: [],
    predefined: ''

  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      items: "journal/getItems"
    })
  },
  watch: {
    show: {
      handler(nv) {
        if (nv === true) {
          this.fetchDocument()
          this.fetchMessages()
          this.getPredefinedMessages()
        } else {
          this.reset()
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchDocument() {
      this.document = this.items.find(i => i.id === this.id)
    },
    fetchMessages() {
      return axios.get(`${MSG}message?declarationId=${this.id}`).then((res) => {
        this.messages = res.data
        this.scrollToBottom()
      })
    },
    getPredefinedMessages() {
      return axios.get(`${MSG}message/predefined/${this.module}`).then((res) => {
        this.actions = res.data
      })
    },
    clear() {
      this.predefined = ''
      this.message = ''
    },
    reset(){
      this.clear()
      this.messages = []
      this.actions = []
      this.document = null
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.messages.scrollTo(0, this.$refs.messages.scrollHeight)
      })
    },
    submit() {
      if (this.predefined.length && this.id && this.module && this.document?.telegram_id) {
        const main = `#${this.module.toUpperCase()}${this.id} ${this.predefined}.`
        axios.post(`${MSG}message/add`, {
          chat_id: this.document.telegram_id,
          declaration_id: this.id,
          message: this.message ? `${main} Комментарий: ${this.message}`.trim() : main
        }).then(() => {
          this.fetchMessages()
          this.clear()
        })
      }

    }
  }


}
</script>
<style scoped>
.messages {
  height: 300px;
  overflow-y: scroll;
}

.message {
  width: max-content;
}

.message__client {
  align-self: end;
}
</style>
