import { render, staticRenderFns } from "./notify-client.vue?vue&type=template&id=a1829152&scoped=true"
import script from "./notify-client.vue?vue&type=script&lang=js"
export * from "./notify-client.vue?vue&type=script&lang=js"
import style0 from "./notify-client.vue?vue&type=style&index=0&id=a1829152&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1829152",
  null
  
)

export default component.exports