var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-overlay',{attrs:{"value":_vm.show}},[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"pb-5",attrs:{"width":"1300px","light":"","color":"#333333"}},[_c('div',{staticClass:"d-flex justify-space-between py-3 px-5"},[_c('h3',[_vm._v(" Чат ")]),_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',[_c('v-col',{staticClass:"white",attrs:{"cols":"12"}},[_c('div',{ref:"messages",staticClass:"messages grey lighten-4 pa-3"},_vm._l((_vm.messages),function(msg,idx){return _c('div',{key:idx,staticClass:"d-flex",class:{
              'justify-end':!msg.user_id
            }},[_c('div',{staticClass:"message rounded pa-2 mb-1",class:{
                'grey lighten-2':msg.user_id,
                'teal lighten-1':!msg.user_id
              }},[_vm._v(" "+_vm._s(msg.message)+" ")])])}),0),_c('div',{staticClass:"predefined my-2"},_vm._l((_vm.actions),function(action,idx){return _c('v-btn',{key:`action-${idx}`,staticClass:"elevation-0 mr-1",class:{
              'teal lighten-2': _vm.predefined === action.text
            },on:{"click":function($event){_vm.predefined = action.text}}},[_vm._v(" "+_vm._s(action.text)+" ")])}),1),_c('v-textarea',{attrs:{"rows":"2","placeholder":"Введите ваше сообщение"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mx-5 mt-5",attrs:{"dark":"","color":"#5CB7B1"},on:{"click":_vm.submit}},[_vm._v(" Отправить ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }